import React from 'react';
import StickyBox from 'react-sticky-box';
import { graphql } from 'gatsby';
import DocFeedback from '@components/DocFeedback';
import DocPageNav from '@components/DocPageNav';
import DocPrevNextNav from '@components/DocPrevNextNav';
import DocsSitemap from '@components/DocsSitemap';
import SliceZone from '@components/SliceZone';
import { DocIdMap } from '@utils/typings/custom';
import * as styles from './styles.module.scss';
import {
  PrismicAcademyPageFragmentFragment,
  PrismicAcademySitemapFragmentFragment,
} from '@utils/typings/prismic-types';

interface Props {
  academyPage: PrismicAcademyPageFragmentFragment;
  academySitemap: PrismicAcademySitemapFragmentFragment;
  orderedPageIds: string[];
  academyIdMap: DocIdMap;
}
export default function AcademyContentLayout({
  academyPage,
  academySitemap,
  orderedPageIds,
  academyIdMap,
}: Props) {
  const group = academySitemap.data.body.find((groupItem) =>
    groupItem.items.find(
      (item) => item.doc_page.id === academyPage.prismicId,
    ),
  );

  const breadcrumb = group
    ? `${group.primary.name.text} / ${academyPage.data.title.text}`
    : academyPage.data.title.text;

  const pageIndex = orderedPageIds.indexOf(academyPage.prismicId);

  return (
    <div className={styles.docPage}>
      <div className={styles.sitemap}>
        <StickyBox offsetTop={72} className={styles.sitemapBox}>
          <DocsSitemap
            sitemap={academySitemap}
            docIdMap={academyIdMap}
            activeUrl={academyPage.url}
          />
        </StickyBox>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.richTextAndNav}>
          <main className={styles.container}>
            <div className={styles.content}>
              <p className={styles.breadcrumb}>{breadcrumb}</p>
              <h1 className={styles.heading}>{academyPage.data.title.text}</h1>
              <SliceZone slices={academyPage.data.body} />
              <div className={styles.feedback}>
                <DocFeedback id={academyPage.id} />
              </div>
            </div>
          </main>
          <DocPageNav slices={academyPage.data.body} />
        </div>
        <DocPrevNextNav
          prev={academyIdMap[orderedPageIds[pageIndex - 1]]}
          next={academyIdMap[orderedPageIds[pageIndex + 1]]}
        />
      </div>
    </div>
  );
}

export const query = graphql`
  fragment PrismicAcademyPageFragment on PrismicAcademyPage {
    _previewable
    url
    uid
    type
    id
    prismicId
    lang
    alternate_languages {
      id
      type
      lang
      uid
    }
    data {
      title {
        text
      }
      body {
        ... on PrismicAcademyPageDataBodyDocsRichText {
          id
          slice_type
          slice_label
          primary {
            rich_text {
              richText
            }
          }
        }
        ... on PrismicAcademyPageDataBodyImportantBlockQuote {
          id
          slice_type
          slice_label
          primary {
            text {
              text
            }
            heading {
              text
            }
          }
        }
        ... on PrismicAcademyPageDataBodyDocsFullWidthImage {
          id
          slice_type
          slice_label
          primary {
            background_image_position
            image {
              url
              alt
            }
          }
        }
      }
      metadata_image {
        alt
        url
      }
      metadata_description {
        text
      }
      metadata_title {
        text
      }
    }
  }
`;
