// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--cF6X9";
export var docPage = "styles-module--doc-page--rhiOc";
export var heading = "styles-module--heading--0t2uL";
export var sitemap = "styles-module--sitemap--ln1pb";
export var sitemapBox = "styles-module--sitemap-box--hDhiH";
export var rightSection = "styles-module--right-section--btTPt";
export var container = "styles-module--container--aLTUi";
export var richTextAndNav = "styles-module--rich-text-and-nav--3SLg2";
export var content = "styles-module--content--TTctL";
export var breadcrumb = "styles-module--breadcrumb--06A1R";
export var feedback = "styles-module--feedback--CYmiK";