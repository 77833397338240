import { useStaticQuery, graphql } from 'gatsby';
import { UsePrismicAcademySitemapQuery } from '@utils/typings/prismic-types';

export function usePrismicAcademySitemap() {
  const query = useStaticQuery<UsePrismicAcademySitemapQuery>(graphql`
    query UsePrismicAcademySitemap {
      prismicAcademySitemap {
        ...PrismicAcademySitemapFragment
      }
    }
  `);

  return query.prismicAcademySitemap;
}
