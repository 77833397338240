import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import linkResolver from '@utils/linkResolver';
import { DocIdMap } from '@utils/typings/custom';
import { AcademyPageTemplateQuery } from '@typings/prismic-types';
import AcademyContentLayout from '@components/AcademyContentLayout';
import { usePrismicAcademySitemap } from '@utils/hooks/usePrismicAcademySitemap';
import { useAllPrismicAcademyPage } from '@utils/hooks/useAllPrismicAcademyPage';
import AcademyLayout from '@components/AcademyLayout';

type Props = PageProps<AcademyPageTemplateQuery>;

export const AcademyPageTemplate = ({ data }: Props) => {
  const allPrismicAcademyPage = useAllPrismicAcademyPage();
  const prismicAcademySitemap = usePrismicAcademySitemap();

  if (!data) return null;

  const academyPage = data.prismicAcademyPage;
  const footer = data.prismicFooter;
  const { metadata_description, metadata_image, metadata_title, title } =
    academyPage.data;

  const academyIdMap: DocIdMap = allPrismicAcademyPage.reduce(
    (obj, item) => ({
      ...obj,
      [item.prismicId]: { title: item.data.title.text, url: item.url },
    }),
    {},
  );

  const orderedPageIds = prismicAcademySitemap.data.top_level_pages
    .map((page) => page.doc_page.id)
    .concat(
      prismicAcademySitemap.data.body.flatMap((group) =>
        group.items.map((page) => page.doc_page.id),
      ),
    );

  const academyMetadata = {
    title: `${metadata_title.text || title.text} - Pry`,
    description: metadata_description.text,
    image: metadata_image.url,
  };

  return (
    <AcademyLayout
      academySitemap={prismicAcademySitemap}
      banner={data.prismicBanner}
      footer={footer}
      academyIdMap={academyIdMap}
      docMetadata={academyMetadata}
      activeUrl={academyPage.url}
      firstDocUrl={academyIdMap[orderedPageIds[0]].url}
    >
      <AcademyContentLayout
        academyPage={academyPage}
        academySitemap={prismicAcademySitemap}
        orderedPageIds={orderedPageIds}
        academyIdMap={academyIdMap}
      />
    </AcademyLayout>
  );
};


export const query = graphql`
  query AcademyPageTemplate($uid: String, $lang: String) {
    prismicAcademyPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      ...PrismicAcademyPageFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
    prismicBanner {
      ...PrismicBannerFragment
    }
  }
`;

export default withPrismicPreview(AcademyPageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
