import clsx from 'clsx';
import * as React from 'react';
import { useState } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import Button from '@components/Button';
import DocsSearch from '@components/DocsSearch';
import DocsSitemap from '@components/DocsSitemap';
import { DocIdMap } from '@utils/typings/custom';
import { PrismicAcademySitemapFragmentFragment } from '@typings/prismic-types';
import CloseIcon from '@icons/close.svg';
import MenuIcon from '@icons/hamburger-menu.svg';
import LogoIcon from '@icons/logo-mark.svg';
import * as styles from './styles.module.scss';

interface Props {
  academySitemap?: PrismicAcademySitemapFragmentFragment;
  academyIdMap: DocIdMap;
  activeUrl: string;
  firstDocUrl: string;
}

export default function AcademyHeader({
  academySitemap,
  academyIdMap,
  activeUrl,
  firstDocUrl,
}: Props) {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <header className={styles.header}>
      <div className={styles.home}>
        <GatsbyLink to="/" className={styles.logo}>
          <LogoIcon />
        </GatsbyLink>
        <span className={styles.divider} />
        <GatsbyLink to={firstDocUrl || '/academy'} className={styles.title}>
          Pry Academy
        </GatsbyLink>
      </div>
      <div className={styles.search}>
        <DocsSearch docsSitemap={academySitemap} docIdMap={academyIdMap} />
      </div>
      <div className={styles.links}>
        {/* TODO:DOCS insert hrefs via graphql */}
        <Button
          href="https://app.pry.co/login"
          useSearchParams
          external
          type="Primary"
          size="Small"
          hasMarginRight={true}
        >
          Sign In
        </Button>
      </div>
      <div className={styles.mobileMenuButton}>
        <Button
          onClick={() => setMobileNavOpen(!mobileNavOpen)}
          type={'Icon'}
          icon={mobileNavOpen ? <CloseIcon /> : <MenuIcon />}
        />
      </div>

      <div
        className={clsx(styles.dropdown, mobileNavOpen && styles.dropdownOpen)}
      >
        <div className={styles.sitemap} onScroll={(e) => e.stopPropagation()}>
          <DocsSitemap
            sitemap={academySitemap}
            docIdMap={academyIdMap}
            activeUrl={activeUrl}
          />
        </div>
      </div>
    </header>
  );
}
