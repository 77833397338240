import { useStaticQuery, graphql } from 'gatsby';
import { UseAllPrismicAcademyPageQuery } from '@utils/typings/prismic-types';

export function useAllPrismicAcademyPage() {
  const query = useStaticQuery<UseAllPrismicAcademyPageQuery>(graphql`
    query UseAllPrismicAcademyPage {
      allPrismicAcademyPage {
        nodes {
          ...PrismicAcademyPageFragment
        }
      }
    }
  `);

  return query.allPrismicAcademyPage.nodes;
}
